<template>
  <section>
    <v-row>
      <v-col v-if="$can('delete', 'analytics')">
        <v-btn
          color="primary"
          to="/administration/users/create"
          @click="addUser()"
        >
          <v-icon left>mdi-plus-thick</v-icon>Add New User
        </v-btn>
      </v-col>

     <v-col cols="4" style="padding: 0 12px 12px 0">
        <v-text-field
          placeholder="search users"
          outlined
          hide-details
          v-model="userSearch"
          clearable
          ><template v-slot:prepend-inner>
            <v-icon large>mdi-magnify</v-icon>
          </template></v-text-field
        >
      </v-col>

    </v-row>
    <confirm-dialog ref="confirmDelete" title="Delete User?"></confirm-dialog>
    <v-data-table
      :headers="headers"
      :items="users"
      v-model="selectedUsers"
      class="elevation-1"
      :loading="loading"
      :search="userSearch"
      :loading-text="`Loading... One moment`"
    >
      <template v-slot:[`item.emailVerified`]="{ item }">
        <td>
          <v-icon v-if="item.emailVerified === true" color="green"
            >check_circle</v-icon
          >
        </td>
      </template>

      <template v-slot:[`item.userId`]="{ item }">
        <td>
          <v-btn
            title="Resend invitation to this user"
            @click="handleReinviteUser(item)"
            icon
            v-if="showOrphans === false && item.emailVerified === false"
          >
            <v-icon medium>mark_as_unread</v-icon>
          </v-btn>

          <v-btn
            :to="`/administration/users/edit/${item.userId}`"
            @click="editItem(users.item)"
            icon
            v-if="showOrphans === false"
          >
            <v-icon medium>edit</v-icon>
          </v-btn>
          <v-btn icon @click="handleUserDelete(item.userId)">
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </td>
      </template>

      <template slot="items" slot-scope="users">
        <td class="text-xs-left">{{ users.item.lastLogin | fromNow }}</td>
        <td :title="users.item.createdAt" class="text-xs-left">
          {{ users.item.created_at | fromNow }}
        </td>
      </template>

      <template v-slot:[`body.append`]="{ headers }">
        <tr>
          <td :colspan="headers.length">
            <v-row>
              <v-col sm="2">
                <v-checkbox
                  :label="`Show orphans`"
                  @change="loadOrphans($event)"
                  v-model="showOrphans"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-tooltip right>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" class="mt-5 ml-n8">help_outline</v-icon>
                  </template>
                  <span
                    >Orphan users are configured in Auth0 but have no
                    corresponding entry in {{ appName }} User table</span
                  >
                </v-tooltip>
              </v-col>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>
  </section>
</template>

<script>
import moment from "moment";
import api from "../_api";
import ConfirmDialog from "../../../../components/Dialogs/ConfirmDialog";

export default {
  components: {
    "confirm-dialog": ConfirmDialog,
  },

  filters: {
    fromNow(date) {
      return moment(date).fromNow();
    },
  },

  data() {
    return {
      headers: [
        { text: "First Name", value: "firstName" },
        { text: "Last Name", value: "lastName" },
        { text: "Email", value: "email" },
        { text: "Invitation Accepted", value: "emailVerified" },
        { text: "Login Count", value: "loginsCount" },
        { text: "Last Logged In At", value: "lastLogin" },
        { text: "Created At", value: "createdAt" },
        { text: "Actions", value: "userId", sortable: false },
      ],
      users: [],
      userSearch: "",
      selectedUsers: [],
      loading: true,
      showOrphans: false,
      appName: process.env.VUE_APP_BRANDING,
    };
  },

  mounted() {
    this.getUsers();
  },

  methods: {
    showOrphanHelp() {},

    editItem(user) {
      this.$emit("onedit", user);
    },

    addUser() {
      this.$emit("oncreateuser");
    },

    async getUsers() {
      this.users = await api.getAllUsers();
      this.loading = false;
    },

    async loadOrphans(state) {
      this.loading = true;
      if (state === true) {
        this.users = await api.getOrphanAuth0Users();
        this.loading = false;
      } else {
        this.getUsers();
      }
    },

    async handleUserDelete(item) {
      const msg =
        "Are you sure you want to delete this user?  Deleting user cannot be undone.";
      const confirmed = await this.$refs.confirmDelete.confirm(msg);

      if (!confirmed) {
        return;
      }

      try {
        await this.deleteUser(item);
        await this.loadOrphans(this.showOrphans);
      } catch (error) {
        console.log(error);
      }
    },

    async handleReinviteUser(item) {
      console.log(item);
      await api.reInviteUser(item.userId);
      this.$toast.show(`${item.email} has been reinvited.`, null, "success");
    },

    async deleteUser(item) {
      console.log(`delete users - showOrphans: ${this.showOrphans}`);
      if (this.showOrphans === true) {
        await api.deleteAuth0User(item);
      } else {
        await api.deleteUser(item);
      }
    },
  },
};
</script>
