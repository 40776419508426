var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-row',[(_vm.$can('delete', 'analytics'))?_c('v-col',[_c('v-btn',{attrs:{"color":"primary","to":"/administration/users/create"},on:{"click":function($event){return _vm.addUser()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-thick")]),_vm._v("Add New User ")],1)],1):_vm._e(),_c('v-col',{staticStyle:{"padding":"0 12px 12px 0"},attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"placeholder":"search users","outlined":"","hide-details":"","clearable":""},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-magnify")])]},proxy:true}]),model:{value:(_vm.userSearch),callback:function ($$v) {_vm.userSearch=$$v},expression:"userSearch"}})],1)],1),_c('confirm-dialog',{ref:"confirmDelete",attrs:{"title":"Delete User?"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"loading":_vm.loading,"search":_vm.userSearch,"loading-text":"Loading... One moment"},scopedSlots:_vm._u([{key:"item.emailVerified",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.emailVerified === true)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("check_circle")]):_vm._e()],1)]}},{key:"item.userId",fn:function(ref){
var item = ref.item;
return [_c('td',[(_vm.showOrphans === false && item.emailVerified === false)?_c('v-btn',{attrs:{"title":"Resend invitation to this user","icon":""},on:{"click":function($event){return _vm.handleReinviteUser(item)}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mark_as_unread")])],1):_vm._e(),(_vm.showOrphans === false)?_c('v-btn',{attrs:{"to":("/administration/users/edit/" + (item.userId)),"icon":""},on:{"click":function($event){return _vm.editItem(_vm.users.item)}}},[_c('v-icon',{attrs:{"medium":""}},[_vm._v("edit")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleUserDelete(item.userId)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1)],1)]}},{key:"items",fn:function(users){return [_c('td',{staticClass:"text-xs-left"},[_vm._v(_vm._s(_vm._f("fromNow")(users.item.lastLogin)))]),_c('td',{staticClass:"text-xs-left",attrs:{"title":users.item.createdAt}},[_vm._v(" "+_vm._s(_vm._f("fromNow")(users.item.created_at))+" ")])]}},{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',[_c('v-col',{attrs:{"sm":"2"}},[_c('v-checkbox',{attrs:{"label":"Show orphans"},on:{"change":function($event){return _vm.loadOrphans($event)}},model:{value:(_vm.showOrphans),callback:function ($$v) {_vm.showOrphans=$$v},expression:"showOrphans"}})],1),_c('v-col',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mt-5 ml-n8"},on),[_vm._v("help_outline")])]}}],null,true)},[_c('span',[_vm._v("Orphan users are configured in Auth0 but have no corresponding entry in "+_vm._s(_vm.appName)+" User table")])])],1)],1)],1)])]}}],null,true),model:{value:(_vm.selectedUsers),callback:function ($$v) {_vm.selectedUsers=$$v},expression:"selectedUsers"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }